import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function Office() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ "margin-bottom": "90px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Our Offices
              </h1>
              <a href className="h5 text-white">
                Our Team
              </a>
              <i className="far fa-circle text-white px-2" />
              <a href className="h5 text-white">
                Our Offices
              </a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ "max-width": "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Contact Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container">
            <div
              className="section-title text-center position-relative pb-3 my-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Contact Us
              </h5>
              <h1 className="mb-0">Dubai Office</h1>
              <p>OGM Business Consultants</p>
            </div>
            <div className="row g-5">
              <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
                <div
                  className="d-flex align-items-center wow fadeIn mt-5 mb-3"
                  data-wow-delay="0.1s"
                >
                  <div
                    className="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-phone-alt text-white" />
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">Call to ask any question</h5>
                    <h4 className="text-primary mb-0">+971502923136 </h4>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center wow fadeIn mt-5 mb-3"
                  data-wow-delay="0.4s"
                >
                  <div
                    className="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-envelope-open text-white" />
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">Email to get free quote</h5>
                    <h4 className="text-primary mb-0">info@ogmholding.com</h4>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center wow fadeIn mt-5"
                  data-wow-delay="0.8s"
                >
                  <div
                    className="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-map-marker-alt text-white" />
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">Visit our office</h5>
                    <h4 className="text-primary mb-0">
                      IFZA Business Park, Dubai, United Arab Emirates.
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
                <iframe
                  className="position-relative rounded w-100 h-100"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.5050501399555!2d55.37514627446814!3d25.11861057776157!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6f9f1fbfb607%3A0x4db0a2f5f59532d9!2sIFZA%20Business%20Park!5e0!3m2!1sen!2sng!4v1711472243033!5m2!1sen!2sng"
                  frameBorder={0}
                  style={{ "min-height": "350px", border: "0" }}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                />
              </div>
            </div>
            <div
              className="section-title text-center position-relative pb-3 my-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h1 className="mb-0 mt-5">UK Office</h1>
              <p>O.G.M Consultants UK Ltd</p>
            </div>
            <div className="row g-5">
              <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
                <iframe
                  className="position-relative rounded w-100 h-100"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.2354241378107!2d-0.09124422400479852!3d51.52724167181779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761d64c8d91b11%3A0x8fd10f948b8c6ef5!2s128%20City%20Rd%2C%20London%20EC1V%202NX%2C%20UK!5e0!3m2!1sen!2sng!4v1711472573140!5m2!1sen!2sng"
                  frameBorder={0}
                  style={{ "min-height": "350px", border: "0" }}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                />
              </div>
              <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
                <div
                  className="d-flex align-items-center wow fadeIn mt-5 mb-3"
                  data-wow-delay="0.1s"
                >
                  <div
                    className="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-phone-alt text-white" />
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">Call to ask any question</h5>
                    <h4 className="text-primary mb-0" />
                  </div>
                </div>
                <div
                  className="d-flex align-items-center wow fadeIn mt-5 mb-3"
                  data-wow-delay="0.4s"
                >
                  <div
                    className="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-envelope-open text-white" />
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">Email to get free quote</h5>
                    <h4 className="text-primary mb-0">info@ogmholding.com</h4>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center wow fadeIn mt-5"
                  data-wow-delay="0.8s"
                >
                  <div
                    className="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-map-marker-alt text-white" />
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">Visit our office</h5>
                    <h4 className="text-primary mb-0">
                      128 City Road, EC1V 2NX, London, United Kingdom.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="section-title text-center position-relative pb-3 my-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h1 className="mb-0 mt-5">USA Office</h1>
              <p>O.G.M. Holding Co. Ltd </p>
            </div>
            <div className="row g-5">
              <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
                <div
                  className="d-flex align-items-center wow fadeIn mt-5 mb-3"
                  data-wow-delay="0.1s"
                >
                  <div
                    className="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-phone-alt text-white" />
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">Call to ask any question</h5>
                    <h4 className="text-primary mb-0" />
                  </div>
                </div>
                <div
                  className="d-flex align-items-center wow fadeIn mt-5 mb-3"
                  data-wow-delay="0.4s"
                >
                  <div
                    className="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-envelope-open text-white" />
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">Email to get free quote</h5>
                    <h4 className="text-primary mb-0">info@ogmholding.com</h4>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center wow fadeIn mt-5"
                  data-wow-delay="0.8s"
                >
                  <div
                    className="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "120px", height: "65px" }}
                  >
                    <i className="fa fa-map-marker-alt text-white" />
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">Visit our office</h5>
                    <h4 className="text-primary mb-0">
                      16192 Coastal Highway, Lewes, P.O. Box 19958 Delaware,
                      United States United States of America.
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
                <iframe
                  className="position-relative rounded w-100 h-100"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3110.9433651072654!2d-75.21415252485413!3d38.765002971752644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b8b90d99b44b9b%3A0xfba6eb4b8adf94b8!2s16192%20Coastal%20Hwy%2C%20Lewes%2C%20DE%2019958%2C%20USA!5e0!3m2!1sen!2sng!4v1711472831020!5m2!1sen!2sng"
                  frameBorder={0}
                  style={{ "min-height": "350px", border: "0" }}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                />
              </div>
            </div>

            {/* <div className="section-title text-center position-relative pb-3 my-5 mx-auto" style={{"max-width":"600px"}}>
        <h1 className="mb-0 mt-5">Cayman Office</h1>
      </div>
      <div className="row g-5">
        <div className="col-lg-6 wow slideInUp" data-wow-delay="0.3s">
          <div className="d-flex align-items-center wow fadeIn mt-5 mb-3" data-wow-delay="0.1s">
            <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{"width":"60px","height":"60px"}}>
              <i className="fa fa-phone-alt text-white" />
            </div>
            <div className="ps-4">
              <h5 className="mb-2">Call to ask any question</h5>
              <h4 className="text-primary mb-0">+012 345 6789</h4>
            </div>
          </div>
          <div className="d-flex align-items-center wow fadeIn mt-5 mb-3" data-wow-delay="0.4s">
            <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{"width":"60px","height":"60px"}}>
              <i className="fa fa-envelope-open text-white" />
            </div>
            <div className="ps-4">
              <h5 className="mb-2">Email to get free quote</h5>
              <h4 className="text-primary mb-0">info@example.com</h4>
            </div>
          </div>
          <div className="d-flex align-items-center wow fadeIn mt-5" data-wow-delay="0.8s">
            <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{"width":"60px","height":"60px"}}>
              <i className="fa fa-map-marker-alt text-white" />
            </div>
            <div className="ps-4">
              <h5 className="mb-2">Visit our office</h5>
              <h4 className="text-primary mb-0">123 Street, NY, USA</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
          <iframe className="position-relative rounded w-100 h-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd" frameBorder={0} style={{"min-height":"350px","border":"0"}} allowFullScreen aria-hidden="false" tabIndex={0} />
        </div>
      </div> */}
          </div>
        </div>
        {/* Contact End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default Office;
