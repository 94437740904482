import React, { Fragment } from "react";
import Navmenu from "./navmenu";

function WhoWeAre() {
  return (
    <Fragment>
      <div>
        <Navmenu />
        <div
          className="container-fluid bg-primary py-5 bg-header imahe"
          style={{ "margin-bottom": "90px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">
                Who We Are
              </h1>
              <a href="our-team.html" className="h5 text-white">
                Our Team
              </a>
              <i className="far fa-circle text-white px-2" />
              <a href="our-offices.html" className="h5 text-white">
                Our Offices
              </a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ "max-width": "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* About Us */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">
                    We Are
                  </h5>
                  <h1 className="mb-0">
                    A Fast Growing Business Consulting Firm With Renowned Global
                    recognition
                  </h1>
                </div>
                <p className="mb-4">
                  Welcome to OGM Business Consultants, your premier destination
                  for comprehensive financial and business advisory services. At
                  O.G.M., we believe in empowering businesses to thrive and
                  succeed in today's dynamic and competitive landscape.
                </p>
                <a
                  href="Quote"
                  className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                  data-wow-delay="0.9s"
                >
                  Request A Quote
                </a>
              </div>
              <div className="col-lg-5" style={{ "min-height": "500px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/fast-growing.jpg"
                    alt="financial-management"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About Us End */}
        {/* Our Mission */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/Our-Mission1.png"
                    alt="financial-goal"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">Our Mission</h1>
                </div>
                <p className="mb-4">
                  Our mission at OGM Business Consultants is to provide
                  exceptional financial and business advisory services that
                  enable our clients to achieve their goals and realize their
                  full potential. We are committed to delivering personalized
                  solutions that drive growth, profitability, and long-term
                  success for businesses of all sizes.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Our Mission End */}
        {/* Our Vision */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">Our Vision</h1>
                </div>
                <p className="mb-4">
                  Our vision is to be the trusted partner of choice for
                  businesses seeking expert guidance and support in navigating
                  the complexities of financial management and business
                  operations. We aspire to be recognized for our unwavering
                  dedication to client satisfaction, innovation, and excellence
                  in everything we do.
                </p>
              </div>
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/vision.jfif"
                    alt="vision"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Our Vision End */}
        {/* Our Values */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-5" style={{ "min-height": "300px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img/our-values1.webp"
                    alt="values"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase" />
                  <h1 className="mb-0">Our Values</h1>
                </div>
                <p className="mb-4">
                  At OGM Business Consultants, our values serve as the
                  foundation of our business and guide our actions and decisions
                  every day:
                </p>
                <ol>
                  <li>
                    <b>Integrity:</b> We uphold the highest standards of
                    integrity, honesty, and ethical conduct in all aspects of
                    our work. Trust and transparency are at the core of
                    everything we do.
                  </li>
                  <li>
                    <b>Excellence:</b> We are committed to delivering excellence
                    in all our services, striving for continuous improvement,
                    and exceeding our clients' expectations at every
                    opportunity.
                  </li>
                  <li>
                    <b>Collaboration:</b> We believe in the power of
                    collaboration and teamwork. By working closely with our
                    clients and leveraging our collective expertise, we can
                    achieve greater success together.
                  </li>
                  <li>
                    <b>Client-Centricity:</b> Our clients are at the heart of
                    everything we do. We are dedicated to understanding their
                    unique needs, providing tailored solutions, and delivering
                    exceptional value and service at every interaction.
                  </li>
                  <li>
                    <b>Innovation:</b> We embrace innovation and creativity as
                    drivers of progress and growth. By staying ahead of the
                    curve and embracing new technologies and best practices, we
                    help our clients stay competitive in a rapidly evolving
                    business landscape.
                  </li>
                </ol>
              </div>
              <p className="mb-4">
                At OGM Business Consultants, we are committed to making a
                positive impact on our clients' businesses and helping them
                navigate the complexities of today's business environment with
                confidence and success.
              </p>
            </div>
          </div>
        </div>
        {/* Our Mission End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default WhoWeAre;
